import React from 'react';
import { StyleSheet, Text, View, SafeAreaView, Dimensions, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import TaskComponent from '../components/taskComponent'
import PageHeader from '../components/pageHeader'

export default function Category({ navigation, route }) {

  const screenWidth = Dimensions.get("window").width;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'white'
    },
    task: {
      borderWidth: 1,
      borderColor: '#ddd',
      paddingVertical: 60,
      overflow: 'hidden',
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 10,
      marginVertical: 10,
      width: screenWidth - 20
    },
  });

  return (
    <ScrollView style={styles.container} contentInsetAdjustmentBehavior="automatic">
      <SafeAreaView>

        <PageHeader title={ route.params?.name } icon={ route.params?.icon } />

        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
        <TaskComponent />
      </SafeAreaView>
    </ScrollView>
  );
}
