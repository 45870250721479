import React, { useState } from 'react';
import { StyleSheet, Text, View, FlatList, TouchableHighlight, TextInput, Dimensions, ScrollView, SafeAreaView  } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import TaskComponent from '../components/taskComponent'

export default function Explore({ navigation }) {
    const screenWidth = Dimensions.get("window").width;

    const [activePreset, setActivePreset] = useState('home')

    const [categories, setCategories] = useState([
        { name: 'Food', icon: 'fast-food-outline' ,key: '1' },
        { name: 'Day out', icon: 'sunny-outline', key: '2' },
        { name: 'Entertainment', icon: 'map-outline', key: '3' },
        { name: 'NIX18', icon: 'wine-outline', key: '4' },
        { name: 'Today', icon: 'flash-outline', key: '5' }
    ])

    const styles = StyleSheet.create({
        container: {
        //   flex: 1,
          backgroundColor: '#fff'
        },
        itemWrapper: {
            padding: 5,
            width: screenWidth / 3,
        },
        item: {
          flex: 1,
          aspectRatio: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FFF9E5',
          borderRadius: 10,
          overflow: 'hidden'
        },
        search: {
            borderWidth: 1,
            borderColor: '#C0C0C0',
            height: 60,
            margin: 20,
            padding: 20,
            borderRadius: 40
        },
        preset: {
            height: 40,
            backgroundColor: '#AFADFF',
            paddingLeft: 60,
            paddingRight: 60,
            overflow: 'hidden',
            borderRadius: 20,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10
        },
        presetActive: {
            backgroundColor: '#5350FF'
        },
        task: {
            borderWidth: 1,
            borderColor: '#ddd',
            paddingVertical: 60,
            overflow: 'hidden',
            borderRadius: 20,
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal: 10,
            marginVertical: 10,
            width: screenWidth - 50
        },
        categoryHeader: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 10
        },
        h2: {
            fontSize: 20,
            fontWeight: 'bold'
        }
      });

    const getHeader = () => {
        return <View>
            <TextInput
                    placeholder="Search for a city"
                    style={styles.search}
                />

            <ScrollView
                horizontal={true}
                style={{ marginBottom: 20, paddingHorizontal: 20 }}
                >
                <TouchableHighlight style={[ styles.preset, activePreset === 'home' ? styles.presetActive : null ]} onPress={() => setActivePreset('home')}>
                    <Text style={{ color: '#fff' }}>Home</Text>
                </TouchableHighlight>
                <TouchableHighlight style={[ styles.preset, activePreset === 'work' ? styles.presetActive : null ]} onPress={() => setActivePreset('work')}>
                    <Text style={{ color: '#fff' }}>Work</Text>
                </TouchableHighlight>
                <TouchableHighlight style={[ styles.preset, activePreset === 'arnhem' ? styles.presetActive : null ]} onPress={() => setActivePreset('arnhem')}>
                    <Text style={{ color: '#fff' }}>Arnhem</Text>
                </TouchableHighlight>
                <TouchableHighlight style={[ styles.preset, activePreset === 'colchester' ? styles.presetActive : null ]} onPress={() => setActivePreset('colchester')}>
                    <Text style={{ color: '#fff' }}>Colchester</Text>
                </TouchableHighlight>
                <TouchableHighlight style={[ styles.preset, activePreset === 'newyork' ? styles.presetActive : null ]} onPress={() => setActivePreset('newyork')}>
                    <Text style={{ color: '#fff' }}>New York</Text>
                </TouchableHighlight>
            </ScrollView>
        </View>
    }

    const getFooter = () => {
        return <View>
            <View style={{ height: 20 }}></View>

            <View style={ styles.categoryHeader }>
                <Text style={ styles.h2 }>Food</Text>
                <Text style={{ color: '#0095ff' }}>See all</Text>
            </View>
            <ScrollView
                horizontal={true}
                style={{ marginBottom: 20 }}
                snapToInterval={screenWidth - 30}
                decelerationRate="fast"
                >
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
            </ScrollView>

            <View style={{ height: 20 }}></View>

            <View style={styles.categoryHeader}>
                <Text style={styles.h2}>Day out</Text>
                <Text style={{color: '#0095ff', textDecorationLine: 'underline'}}>See all</Text>
            </View>
            <ScrollView
                horizontal={true}
                style={{ marginBottom: 20 }}
                snapToInterval={screenWidth - 30}
                decelerationRate="fast"
                >
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
            </ScrollView>

            <View style={{ height: 20 }}></View>

            <View style={styles.categoryHeader}>
                <Text style={styles.h2}>Entertainment</Text>
                <Text style={{color: '#0095ff', textDecorationLine: 'underline'}}>See all</Text>
            </View>
            <ScrollView
                horizontal={true}
                style={{ marginBottom: 20 }}
                snapToInterval={screenWidth - 30}
                decelerationRate="fast"
                >
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
                <TaskComponent insideSlider={true} />
            </ScrollView>
        </View>
    }

    return (
        <SafeAreaView style={styles.container}>
            <FlatList
                contentContainerStyle={styles.list}
                data={categories}
                numColumns={3}
                renderItem={({ item }) => (
                    <View style={styles.itemWrapper}>
                        <TouchableHighlight onPress={() => navigation.navigate('Category', item)}>
                            <View style={styles.item}>
                                <Ionicons name={ item.icon } size={40} color='black' style={{ marginBottom: 5 }} />
                                <Text>{ item.name }</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                )}
                ListHeaderComponent={getHeader}
                ListFooterComponent={getFooter}
            />
        </SafeAreaView>
    );
}
