import React, { useState } from 'react';
import { StyleSheet, Text, View, StatusBar } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from '@expo/vector-icons';

import ExploreScreen from './screens/exploreScreen';
import CategoryScreen from './screens/categoryScreen';
import LoginScreen from './screens/loginScreen';
import ProfileScreen from './screens/profileScreen';
import WalletScreen from './screens/walletScreen';
import AssignmentScreen from './screens/assignmentScreen';
import MyAssignmentsScreen from './screens/myAssignmentsScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function HomeTabs() {
  return (
    <Tab.Navigator tabBarOptions={{ showLabel: false, activeTintColor: 'red' }}>
      <Tab.Screen
        name="Explore"
        component={ExploreScreen}
        options={{
          tabBarIcon: () => (<Ionicons name="search-outline" size={26} color='#004A80' />)
        }}
      />
      <Tab.Screen
        name="MyAssignments"
        component={MyAssignmentsScreen}
        options={{
          tabBarIcon: () => (<Ionicons name="calendar-outline" size={26} color='#004A80' />)
        }}
      />
      <Tab.Screen
        name="Wallet"
        component={WalletScreen}
        options={{
          tabBarIcon: () => (<Ionicons name="cash-outline" size={26} color='#004A80' />),
          tabBarBadge: 3
        }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          tabBarIcon: () => (<Ionicons name="ellipsis-horizontal-outline" size={26} color='#004A80' />)
        }}
      />
    </Tab.Navigator>
  );
}

export default function App() {
  return (
    <NavigationContainer>
      <StatusBar barStyle="dark-content" backgroundColor="#fff" />

      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
      >
        <Stack.Screen name="Login" component={ LoginScreen } />
        <Stack.Screen name="Home" component={ HomeTabs } />
        <Stack.Screen name="Category" component={ CategoryScreen } />
        <Stack.Screen name="Assignment" component={ AssignmentScreen } />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
});
