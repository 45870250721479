import React from 'react';
import { SafeAreaView, StyleSheet, Text, View, TouchableOpacity, ScrollView } from 'react-native';

import PageHeader from '../components/pageHeader'

export default function Login({ navigation, route }) {

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={{ width: '100%' }}>
        <PageHeader title="Profile" icon="person-outline" />
        
        <View style={{ paddingHorizontal: 20 }}>
          <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Login')}>
              <Text style={{ color: 'white', fontSize: 18 }}>Logout</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  button: {
    backgroundColor: '#FF5064',
    borderRadius: 10,
    width: '100%',
    height: 55,
    justifyContent: 'center',
    alignItems: 'center'
  }
});
