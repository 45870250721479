import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function PageHeader({ title, icon }) {    
    const styles = StyleSheet.create({
        header: {
            flex: 1,
            width: '100%',
            paddingVertical: 20,
            paddingHorizontal: 20,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        }
    });

    return (
        <View style={styles.header}>
            <Ionicons name={ icon ?? 'flash-outline' } size={40} color='black' style={{ marginRight: 10 }} />
            <Text style={{ fontSize: 30, fontWeight: 'bold' }}>{ title }</Text>
        </View>
    )
}