import React, { useState } from 'react';
import { RefreshControl, SafeAreaView, StyleSheet, Text, View, ActivityIndicator, ScrollView } from 'react-native';

import TaskComponent from '../components/taskComponent'
import PageHeader from '../components/pageHeader'

export default function Login({ navigation, route }) {
    const [refreshing, setRefreshing] = React.useState(false);

    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    // if (refreshing) {
    //     return (
    //         <SafeAreaView style={styles.container}>
    //             <ActivityIndicator color="#0095ff" size="large" />
    //         </SafeAreaView>
    //     );
    // }

    return (
        <ScrollView
            refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }>
            <SafeAreaView style={styles.container}>
                <PageHeader title="Wallet" icon="cash-outline" />

                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
                <TaskComponent />
            </SafeAreaView>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
});
