import React from 'react';
import { StyleSheet, Text, View, SafeAreaView, TouchableHighlight, Image, TextInput, ScrollView } from 'react-native';

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingTop: 50,
      paddingBottom: 20
    },
    button: {
        backgroundColor: '#5350FF',
        borderRadius: 10,
        width: '100%',
        height: 55,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: 200,
        height: 100,
        marginBottom: 40,
        resizeMode: 'contain'
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        width: '100%',
        height: 55,
        paddingHorizontal: 20,
        marginBottom: 20,
        borderRadius: 10
    }
});

export default function Login({ navigation, route }) {

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
        <ScrollView contentContainerStyle={styles.container}>
            <Image
                style={styles.logo}
                source={require('../assets/logo.png')}
            />
            <TextInput style={ styles.input } placeholder="Email" keyboardType="email-address" />
            <TextInput style={ styles.input } placeholder="Password" secureTextEntry={true} />
            <TouchableHighlight style={styles.button} onPress={() => navigation.navigate('Home')}>
                <Text style={{ color: 'white', fontSize: 18 }}>Login</Text>
            </TouchableHighlight>
        </ScrollView>
    </SafeAreaView>
  );
}
