import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Dimensions, Image, Modal } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const screenWidth = Dimensions.get("window").width;

export default function TaskComponent({ insideSlider }) {
    const [modalVisible, setModalVisible] = React.useState(false);

    const navigation = useNavigation();
    
    const styles = StyleSheet.create({
        task: {
          borderWidth: 1,
          borderColor: '#ddd',
          overflow: 'hidden',
          borderRadius: 20,
          marginHorizontal: 10,
          marginVertical: 10,
          width: insideSlider ? screenWidth - 50 : screenWidth - 20
        },
        image: {
            height: 200,
            width: '100%',
            resizeMode: 'cover',
            backgroundColor: '#ddd'
        },
        logo: {
            height: 80,
            width: 80,
            resizeMode: 'cover',
            position: 'absolute',
            top: 160,
            left: 10,
            borderRadius: 80,
            borderColor: '#ddd',
            borderWidth: 1
        },
        taskContent: {
            paddingTop: 20,
            paddingLeft: 100,
            paddingRight: 20,
            paddingBottom: 20
        },
        textFaded: {
            opacity: 0.5
        },
        corner: {
            width: 150,
            height: 100,
            backgroundColor: '#ADF0FF',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingBottom: 10,
            transform: [
                {
                    rotateZ: '45deg'
                }
            ],
            position: 'absolute',
            top: -45,
            right: -70
        },
        button: {
            backgroundColor: '#ddd',
            borderRadius: 10,
            width: '100%',
            height: 55,
            justifyContent: 'center',
            alignItems: 'center'
        },
        buttonPrim: {
            backgroundColor: '#5350FF',
            borderRadius: 10,
            width: '100%',
            height: 55,
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    const goToAssignment = () => {
        setModalVisible(false)
        navigation.navigate('Assignment')
    }

    return (
        <View style={ styles.task }>
            <Modal
                presentationStyle="formSheet"
                visible={modalVisible}
                animationType='slide'
                >
                <View style={{ padding: 40 }}>
                    <TouchableOpacity onPress={() => goToAssignment()} style={styles.buttonPrim}>
                        <Text style={{ color: 'white' }}>Go to assignment</Text>
                    </TouchableOpacity>

                    <View style={{ height: 20 }}></View>

                    <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.button}>
                        <Text>Close</Text>
                    </TouchableOpacity>
                </View>
            </Modal>

            <TouchableOpacity
                // onPress={() => navigation.navigate('Assignment')}
                onPress={() => setModalVisible(true)}
            >
                <View>
                    <Image
                        style={styles.image}
                        source={require('../assets/pizza.jpeg')}
                    />
                    <Image
                        style={styles.logo}
                        source={require('../assets/dominos.jpg')}
                    />
                    <View style={styles.taskContent}>
                        <Text style={{ fontSize: 18, marginBottom: 5, fontWeight: 'bold' }}>Buy a pizza</Text>
                        <Text style={styles.textFaded}>Central Station <Text style={styles.textFaded}>Arnhem</Text></Text>
                    </View>
                    <View style={styles.corner}>
                        <Text>5km</Text>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}